<template>
  <div>
    <v-hover v-slot="{ hover }" open-delay="50">
      <v-card :elevation="hover ? 6 : 2" rounded="0" :color="hover ? 'grey lighten-5' : null" class="mb-1">
        <!-- {{ cargoInternal }} -->
        <v-card-text v-if="cargoInternal">
          <v-container fluid class="ma-0 pa-0">
            <v-row align="center">
              <v-col cols="12">
                <v-container fluid>
                  <v-row align="center">
                    <v-col :cols="!readOnly ? 10 : 12" class="text-h5 text-left mx-0 px-0">
                      <v-icon size="50" class="mr-2 pb-1">{{ cargoInternal.cargoType === "goods" ? "mdi-dolly" : "mdi-view-week-outline" }}</v-icon>
                      {{ cargoInternal.code }}
                    </v-col>
                    <v-col cols="2" class="text-right mx-0 px-0" v-if="!readOnly">
                      <v-menu bottom left offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list color="grey lighten-4">
                          <v-list-item @click="infoDialogVisible = true">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="primary">mdi-information-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="text-uppercase">{{ tLabel("Info") }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="prepareUpdateVisitPosition" v-if="editPositionVisible === true">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="primary">mdi-warehouse</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="text-uppercase">{{ tLabel("Assegna posizione") }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="false">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="primary">mdi-file-sign</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="text-uppercase">{{ tLabel("Vai all'ordine") }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item @click="removeDialogVisible = true" v-if="removeEnabled === true">
                            <v-list-item-icon class="mr-3">
                              <v-icon color="error">mdi-close</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title class="text-uppercase">{{ tLabel("Rimuovi") }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
                <v-divider class="primary mb-3" />
                <v-container fluid v-if="cargoInternal.cargoType === 'goods'">
                  <v-row align="center">
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.goodType" :label="tLabel('Type')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.description" :label="tLabel('Description')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.owner" :label="tLabel('Owner')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.orderCode" :label="tLabel('Order')" />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.position" :label="tLabel('Position')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :numericValue="cargoInternal.quantity" :label="tLabel('Colli')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :numericValue="cargoInternal.weight" :label="tLabel('Gross weight')" />
                    </v-col>
                    <v-col cols="2">
                      <LabeledContent :label="tLabel('Dangerous')">
                        <template v-slot:content>
                          <div><v-icon v-if="cargoInternal.dangerous && cargoInternal.dangerous === true" color="warning">mdi-alert</v-icon></div>
                        </template>
                      </LabeledContent>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid v-else>
                  <v-row align="center">
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.type" :label="tLabel('Type')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.owner" :label="tLabel('Owner')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.orderCode" :label="tLabel('Order')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.position" :label="tLabel('Position')" />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="4">
                      <LabeledContent :numericValue="cargoInternal.tare" :label="tLabel('Tare Mass')" />
                    </v-col>
                    <v-col cols="4">
                      <LabeledContent :numericValue="cargoInternal.weight" :label="tLabel('Gross weight')" />
                    </v-col>
                    <v-col cols="2">
                      <LabeledContent :value="cargoInternal.damaged && cargoInternal.damaged === true ? 'Sì' : 'No'" :label="tLabel('Damaged')" />
                    </v-col>
                    <v-col cols="2">
                      <LabeledContent :label="tLabel('Dangerous')">
                        <template v-slot:content>
                          <div><v-icon v-if="cargoInternal.dangerous && cargoInternal.dangerous === true" color="warning">mdi-alert</v-icon></div>
                        </template>
                      </LabeledContent>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="3">
                      <LabeledContent :label="tLabel('Full/Empty')">
                        <template v-slot:content>
                          <EnumVisualization :value="cargoInternal.fullEmpty" :enums="fullEmpties" :enumsMap="fullEmptiesMap" />
                        </template>
                      </LabeledContent>
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.seal1" :label="tLabel('Seal 1')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.seal2" :label="tLabel('Seal 2')" />
                    </v-col>
                    <v-col cols="3">
                      <LabeledContent :value="cargoInternal.seal3" :label="tLabel('Seal 3')" />
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid>
                  <v-row align="center">
                    <v-col cols="4">
                      <LabeledContent :value="cargoInternal.itineraryVisit ? cargoInternal.itineraryVisit.departure : null" :label="tLabel('Departure')" />
                    </v-col>
                    <v-col cols="4">
                      <LabeledContent :value="cargoInternal.itineraryVisit ? cargoInternal.itineraryVisit.departureLocode : null" :label="tLabel('Departure Locode')" />
                    </v-col>
                    <v-col cols="4">
                      <LabeledContent :value="cargoInternal.itineraryVisit ? cargoInternal.itineraryVisit.departureCompany : null" :label="tLabel('Departure Company')" />
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="4">
                      <LabeledContent :value="cargoInternal.itineraryVisit ? cargoInternal.itineraryVisit.destination : null" :label="tLabel('Destination')" />
                    </v-col>
                    <v-col cols="4">
                      <LabeledContent :value="cargoInternal.itineraryVisit ? cargoInternal.itineraryVisit.destinationLocode : null" :label="tLabel('Destination Locode')" />
                    </v-col>
                    <v-col cols="4">
                      <LabeledContent :value="cargoInternal.itineraryVisit ? cargoInternal.itineraryVisit.destinationCompany : null" :label="tLabel('Destination Company')" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-hover>
    <v-dialog v-model="positionDialogVisible" max-width="600" scrollable>
      <v-card>
        <v-card-title class="text-h5 text-uppercase panelheader">
          {{ tLabel("Position") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field class="text-h5" :label="tLabel('Position')" v-model="positionCode" clearable />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined large @click="positionDialogVisible = false" class="mr-2"> <v-icon class="pr-2">mdi-close</v-icon>{{ tLabel("Close") }} </v-btn>
          <v-btn large color="primary" @click="updateVisitPosition"> <v-icon class="pr-2">mdi-content-save</v-icon>{{ tLabel("Save") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="removeDialogVisible" max-width="600">
      <v-card>
        <v-card-title class="text-h6 text-uppercase panelheader">{{ tLabel("Rimuovi") }}</v-card-title>
        <v-card-text class="text-h6 py-2" v-if="cargoInternal !== null"> Rimuovere {{ cargoInternal.code }} ? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="removeDialogVisible = false" class="mr-2"><v-icon class="pr-2">mdi-cancel</v-icon>{{ tLabel("Annulla") }}</v-btn>
          <v-btn color="primary" @click="remove"><v-icon>mdi-delete</v-icon>{{ tLabel("Rimuovi") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoDialogVisible" scrollable persistent ref="viewDetail">
      <v-card>
        <v-card-title class="text-h5 text-uppercase panelheader">
          {{ tLabel("Visit info") }}
          <v-spacer />
          <v-icon @click="closeInfoDialog" large>mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <VisitDetails
            :key="visitDetailsKey"
            v-if="cargoInternal"
            :visitId="cargoInternal.visitId"
            :visitSysType="cargoInternal.cargoType === 'goods' ? 'GOO' : 'UNT'"
            standalone="false"
            @visitEditStatusChanged="onVisitEditStatusChange"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="closeInfoDialog"><v-icon class="mr-2">mdi-close</v-icon> {{ tLabel("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDialogVisible" persistent width="600">
      <v-card>
        <v-card-title class="text-h5 text-uppercase panelheader">
          {{ tLabel("Confirm") }}
        </v-card-title>
        <v-card-text class="text-h6">
          {{ tLabel("Le modifiche non sono state salvate.") }}
          <br />
          {{ tLabel("Proseguire senza salvare?") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="confirmDialogVisible = false">{{ tLabel("No") }}</v-btn>
          <v-btn
            class="primary"
            @click="
              infoDialogVisible = false;
              confirmDialogVisible = false;
              ++visitDetailsKey;
            "
            >{{ tLabel("Si") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import YardEnumMixin from "../../mixins/yard/EnumMixin";
import LabeledContent from "@/components/yard/LabeledContent.vue";
import EnumVisualization from "@/components/yard/EnumVisualization.vue";
import VisitDetails from "@/views/yard/inventory/VisitDetails.vue";

export default {
  name: "CargoCard",
  data() {
    return {
      positionCode: null,
      positionDialogVisible: false,
      removeDialogVisible: false,
      infoDialogVisible: false,
      cargoInternal: null,
      visitEditing: false,
      confirmDialogVisible: false,
      visitDetailsKey: 0,
    };
  },
  props: {
    cargo: {
      type: Object,
      required: true,
    },
    removeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    editPositionVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["removeCargoVisit", "cargoSaved"],
  components: { LabeledContent, EnumVisualization, VisitDetails },
  mixins: [YardEnumMixin],
  async mounted() {
    this.cargoInternal = JSON.parse(JSON.stringify(this.cargo));
  },
  methods: {
    prepareUpdateVisitPosition() {
      this.positionCode = this.cargoInternal.position;
      this.positionDialogVisible = true;
    },
    async updateVisitPosition() {
      await this.$api.yardData.setVisitsPositions([
        {
          id: this.cargoInternal.visitId,
          positionCode: this.positionCode,
        },
      ]);
      this.cargoInternal.position = this.positionCode;
      this.positionDialogVisible = false;
    },
    remove() {
      this.$emit("removeCargoVisit", this.cargoInternal.visitId);
      this.removeDialogVisible = false;
    },
    async onVisitEditStatusChange(visitId, status) {
      console.log("CargoCard onVisitEditStatusChange --------------------- ", visitId, status);
      this.visitEditing = false;
      if (status && status === "IN_PROGRESS") {
        this.visitEditing = true;
      }
      if (status && status === "SAVED") {
        this.cargoInternal = await this.$api.yardData.getVisitById(visitId);
        this.$emit("cargoSaved", visitId);
      }
    },
    closeInfoDialog() {
      if (!this.visitEditing) {
        this.infoDialogVisible = false;
      } else {
        this.confirmDialogVisible = true;
      }
    },
  },
};
</script>

<style></style>
