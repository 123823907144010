<template>
  <div>
    <v-container fluid class="mb-3 px-0">
      <v-row align="center">
        <v-col cols="12" class="mb-0 pb-0">
          <v-card elevation="2" rounded="0" class="mb-1" color="grey lighten-3">
            <v-card-text>
              <v-row align="center">
                <v-col cols="10" class="text-h5 text-uppercase font-weight-light">
                  {{ tLabel("Pick-up") }}
                </v-col>
                <v-col cols="2" class="text-right" v-if="!readOnly && addEnabled">
                  <v-btn outlined color="primary" :title="tLabel('Aggiungi unit/merce')" @click="prepareAdd">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="mt-0 pt-0" v-if="cargoes !== null && cargoes.length > 0">
          <div v-for="cargo in cargoes" :key="cargo.visitId">
            <CargoCard :cargo="cargo" :read-only="readOnly" :remove-enabled="removeEnabled" @removeCargoVisit="removeVisit" />
          </div>
        </v-col>
        <v-col cols="12" class="text-subtitle-1 font-weight-light" v-else> Nessuna merce/unit in ritiro. </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="addDialogVisible" fullscreen scrollable>
      <v-card rounded="0" v-if="addDialogVisible">
        <v-card-title class="text-h5 text-uppercase panelheader">
          {{ tLabel("Aggiungi ritiro") }}
          <v-spacer />
          <v-icon large @click="closeAddDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <CargoesSearchAndSelectionPanel ref="cargoesSearchAndSelectionPanel" :cargoes="cargoesToAdd" :cargoType="cargoType" @cargoTypeChanged="changeCargoType" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined large @click="closeAddDialog" class="mr-2"> <v-icon class="pr-2">mdi-close</v-icon>{{ tLabel("Close") }} </v-btn>
          <v-btn large color="primary" :disabled="cargoesToAdd.length === 0" @click="add"> <v-icon class="pr-2">mdi-plus</v-icon>{{ tLabel("Aggiungi") }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CargoCard from "@/components/yard/CargoCard.vue";
import CargoesSearchAndSelectionPanel from "@/components/yard/CargoesSearchAndSelectionPanel";
import ToastMixin from "../../mixins/ToastMixin";

export default {
  name: "PickupPanel",
  data() {
    return {
      cargoes: null,
      cargoesToAdd: [],
      cargoType: null,
      addDialogVisible: false,
    };
  },
  props: {
    truckVisitId: {
      type: String,
      required: true,
    },
    removeEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    addEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { CargoCard, CargoesSearchAndSelectionPanel },
  mixins: [ToastMixin],
  async mounted() {
    this.initItems();
  },
  methods: {
    async initItems() {
      let truckTransit = await this.$api.yardData.getTruckTransitByVisitId(this.truckVisitId);
      if (truckTransit && truckTransit !== null && truckTransit.cargoes && truckTransit.cargoes !== null) {
        this.cargoes = truckTransit.cargoes.filter((c) => c.direction === "OUT");
      }
      console.log("PICKUP initItems", truckTransit, this.cargoes);
    },
    prepareAdd() {
      this.cargoesToAdd = [];
      this.cargoType = null;
      if (this.cargoes !== null && this.cargoes.length > 0) {
        this.cargoType = this.cargoes[0].cargoType === "goods" ? "GOO" : "UNT";
      }
      this.addDialogVisible = true;
    },
    async add() {
      console.log("Pickup add", this.truckVisitId, this.cargoType, this.cargoesToAdd);
      try {
        if (this.cargoType === "GOO") {
          await this.$api.yardData.addGoodsToTruckVisit(this.truckVisitId, this.cargoesToAdd);
        } else if (this.cargoType === "UNT") {
          await this.$api.yardData.addUnitsToTruckVisit(this.truckVisitId, this.cargoesToAdd);
        }
        this.closeAddDialog();
        this.initItems();
        this.showSuccess("Aggiunta merce/unit ritiro avvenuta correttamente.");
      } catch (ex) {
        this.showError("Errore aggiunta merce/unit ritiro." || ex);
      }
    },
    async removeVisit(visitId) {
      console.log("removeVisit visitId", visitId);
      if (this.cargoes !== null && this.cargoes.length > 0) {
        let cargoesByVisitId = this.cargoes.filter((i) => i.visitId === visitId);
        if (cargoesByVisitId.length > 0) {
          let handlingId = cargoesByVisitId[0].handlingId;
          console.log("removeVisit handlingId", handlingId);
          try {
            await this.$api.yardData.removeByHandlingId(handlingId);
            this.showSuccess("Rimozione merce/unit ritiro avvenuta correttamente.");
            var index = this.cargoes.indexOf(cargoesByVisitId[0]);
            if (index > -1) {
              this.cargoes.splice(index, 1);
            }
          } catch (ex) {
            this.showError("Errore rimozione merce/unit ritiro." || ex);
          }
        }
      }
    },
    closeAddDialog() {
      this.addDialogVisible = false;
    },
    changeCargoType(type) {
      console.log("changeCargoType", type);
      this.cargoType = null;
      if (type && type !== null) {
        this.cargoType = type;
      }
    },
  },
};
</script>

<style></style>
